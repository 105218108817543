import { addStudent } from 'API/course'
import { navigate, PageProps } from 'gatsby'
import { useCourseHook } from 'hooks/useCourseHook'
import { useGetUsersHook } from 'hooks/useGetUsersHook'
import React, { Fragment } from 'react'
import { useState } from 'react'

const AddStudentToCourse = (_props: PageProps) => {
	const [search, setSearch] = useState<string>('')
	const { course } = useCourseHook(_props.params.id)
	const { UsersTable } = useGetUsersHook({
		search,
		action_name: 'Add',
		action: async (user_id: string) => {
			if (course) {
				const resp = await addStudent(user_id, course.uid)
				if (resp) {
					navigate(`/admin/course/${course.uid}`)
				}
			}
		},
	})
	return (
		<Fragment>
			<input type='text' name='search' id='search' onChange={(e) => setSearch(e.target.value)} />

			{UsersTable}
		</Fragment>
	)
}

export default AddStudentToCourse
